import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms and Conditions
      </Typography>

      <Typography variant="body1" gutterBottom>
        Welcome to Akselerasi Digital Nusantara. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.
      </Typography>

      <Box sx={{ my: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body2" paragraph>
          By using this website, you agree to be bound by these terms and conditions, which govern your use of the site and our services.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          2. Modification of Terms
        </Typography>
        <Typography variant="body2" paragraph>
          We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on the website. Continued use of the site after changes are made constitutes your acceptance of the revised terms.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          3. User Responsibilities
        </Typography>
        <Typography variant="body2" paragraph>
          You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit the use and enjoyment of this site by any third party.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          4. Intellectual Property
        </Typography>
        <Typography variant="body2" paragraph>
          All content on this website, including text, graphics, logos, and images, is the property of Akselerasi Digital Nusantara and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or use any content without our explicit permission.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          5. Privacy Policy
        </Typography>
        <Typography variant="body2" paragraph>
          Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body2" paragraph>
          Akselerasi Digital Nusantara will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to, or use of, this website.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          7. Governing Law
        </Typography>
        <Typography variant="body2" paragraph>
          These terms and conditions are governed by and construed in accordance with the laws of Indonesia. Any disputes arising from the use of this website will be resolved in the courts of Indonesia.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          8. Contact Information
        </Typography>
        <Typography variant="body2" paragraph>
          If you have any questions or concerns regarding these terms, please contact us at delphito@adndigitalsolution.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
