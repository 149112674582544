import React from 'react';
import { Button, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../Atomics/GradientButton';

const HeaderButtons = () => {
  const navigate = useNavigate();

  const handleConsultationClick = () => {
    // window.location.href = 'mailto:delphito@adndigitalsolution.com';
    navigate('/contact');
    // scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 250);
    }
  };

  return (
    <Container id="HeaderButtons" sx={{ mb: { xs: 4, sm: 12 } }}>
      <Box display="flex" justifyContent="center" mt={2}>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleConsultationClick}
          sx={{ marginRight: 2 }}
        >
          Free Consultation
        </Button> */}
        <GradientButton text="Free Consultation" onClick={handleConsultationClick} sx={{ marginRight: 2 }} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => scrollToSection('service-cards')}
        >
          Our Expertise
        </Button>
      </Box>
    </Container>
  );
};

export default HeaderButtons;
