import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../../getLPTheme';
import Hero from '../../components/Hero';
import { useThemeMode } from '../../hooks/ThemeModeContext';
import StartupStatistics from '../../components/StarUp/StartupStatistics';
import ContactUs from '../../components/ContactUs';

const StarUp = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  const handleEmailClick = () => {
    window.location.href = 'mailto:delphito@adndigitalsolution.com';
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        {/* Hero Section */}
        <Hero prefixTitle="Star" title="+Up" description="" />

        {/* Startup Info Section */}
        <Container maxWidth="md" sx={{ my: 8 }}>
          {/* Package Offering Section */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              Beat the Startup Odds With Our Start-Ups Package
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              With 90% of startups failing and the average launch cost exceeding $3,000, the stakes are high for new businesses.
              Common pitfalls include a lack of product-market fit—affecting 34% of failures—and ineffective marketing strategies,
              which contribute to 22% of startup closures. We provide the tools you need to defy the odds and accelerate your path to success.
            </Typography>
          </Box>

        </Container>

        <StartupStatistics />

        <ContactUs />

        {/* <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEmailClick}
            sx={{ borderRadius: 2, py: 1.5, px: 4 }}
          >
            Start Discuss
          </Button>
        </Box> */}
      </Box>
    </ThemeProvider>
  );
}

export default StarUp;
