import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { BlueGradientBox } from '../../atoms/GradientBoxes';

export default function CallToActionBox() {
  const navigate = useNavigate();

  const handleConsultationClick = () => {
    window.location.href = 'mailto:delphito@adndigitalsolution.com';
    // navigate('/contact');
  };

  return (
    <Container sx={{ py: { xs: 8, sm: 16 } }}>
      <BlueGradientBox>
        {/* Adjust typography to be responsive */}
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', sm: '2rem', md: '2rem' }, // Adjusting font size for different screen sizes
            textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile, left-align on larger screens
          }}
        >
          Transform Your Business Today
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1rem' }, // Smaller font size for mobile
            textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile, left-align on larger screens
          }}
        >
          Contact us now for a personalized consultation and discover how we can help you grow your business.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: '2%',
            padding: { xs: '8px 20px', sm: '10px 24px' }, // Adjust button padding for mobile
            fontSize: { xs: '0.875rem', sm: '1rem' }, // Adjust button text size for mobile
          }}
          onClick={handleConsultationClick}
        >
          Free Consultation
        </Button>
      </BlueGradientBox>
    </Container>
  );
}
