import React from 'react';
import { Box, Container, Grid, Typography, TextField, Button, Link, Paper } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const ContactUs = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Us
      </Typography>

      {/* Contact Information */}
      <Grid container spacing={4} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" component="h2" gutterBottom>
            Mobile
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PhoneIcon sx={{ mr: 1 }} />
            <Link href="tel:+6285752242018" underline="none">
              +6285752242018
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WhatsAppIcon sx={{ mr: 1, color: 'green' }} />
            <Link href="https://wa.me/6285752242018" underline="none">
              WhatsApp Us
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" component="h2" gutterBottom>
            Email
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EmailIcon sx={{ mr: 1 }} />
            <Link href="mailto:delphito@adndigitalsolution.com" underline="none">
              delphito@adndigitalsolution.com
            </Link>
          </Box>
        </Grid>
      </Grid>

      {/* Contact Form */}
      {/* <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Send Us a Message
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                required
                InputLabelProps={{
                  style: { fontSize: '0.8rem' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                required
                InputLabelProps={{
                  style: { fontSize: '0.8rem' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                required
                InputLabelProps={{
                  style: { fontSize: '0.8rem' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Website (if any)"
                variant="outlined"
                InputLabelProps={{
                  style: { fontSize: '0.8rem' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                variant="outlined"
                multiline
                rows={6}
                required
                InputLabelProps={{
                  style: { fontSize: '0.8rem' },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    minHeight: '150px', // Set a minimum height for the text area
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper> */}
    </Container>
  );
};

export default ContactUs;
